import React, { useEffect } from 'react'
import Menu from './Menu'
import top from '../assets/logo.png';
import icon_1 from '../assets/icon_1.png';
import icon_3 from '../assets/icon_3.png';
import bg_top from '../assets/bg_top.png';
import right from '../assets/right.png';
import left from '../assets/left.png';
import bottom from '../assets/bottom.png';
import telegram from '../assets/telegram.png';
import x from '../assets/x.png';
import Aos from 'aos';
import 'aos/dist/aos.css';


export default function Home() {

    useEffect(() => {
        Aos.init({
            duration: 800,
            once: false
        });
        Aos.refresh();
    }, [])

    return (
        <div className="bg-black-100 font-exo overflow-hidden text-white-100">

            {/* top section */}

            <img data-aos='fade-down' src={bg_top} className='absolute top-0 left-0 right-0'></img>

            <div className="lg:px-20 px-5 bg-top relative z-20">
                <div className='container mx-auto'>

                    <Menu />
                    <div className="flex flex-col justify-center items-center gap-y-12 py-20">

                        <img data-aos='zoom-in' src={top} alt='logo' className='lg:w-1/3 rounded-full border-4' loading='lazy'></img>

                        <div id='about' className='gap-y-4 text-center flex flex-col items-center justify-center'>
                            <p data-aos='fade-down' className='lg:w-1/2 lg:text-lg'>
                                Don't worry if you missed $GROK; here is your second chance, $XGROK. Following Grok Meta, which launched on a Friday after Elon Musk's tweets about his AI startup, XAI is working on its own version of ChatGPT called Grok on the X platform. We combined Grok and X together in a token, which will be the next mooner. Many people missed Grok; this is your next chance. Don't miss $XGROK.
                            </p>
                        </div>

                        {/* <div className='lg:flex items-center justify-center gap-x-8'>

                            <a href='https://airdrop.xdogebird.com/'>
                                <button className='px-6 py-3 bg-black-50 hover:bg-black-100 hover:border lg:text-4xl text-2xl font-semibold mt-6 rounded-2xl flex items-center gap-x-6'>
                                    Airdrop
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                                    </svg>
                                </button>
                            </a>

                            <a href='https://twitter.com/SolidProof_io/status/1687128242568777728?s=20'>
                                <button className='px-6 py-3 hover:bg-black-50 lg:text-4xl text-2xl font-semibold mt-6 rounded-2xl flex items-center gap-x-6 border hover:border-0'>
                                    Audit

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                                    </svg>
                                </button>
                            </a>
                        </div> */}

                        <div className='flex flex-wrap justify-center items-center gap-8'>
                            <a href='https://t.me/xgrokerc20'>
                                <img data-aos='fade-right' src={icon_1} alt='logo' className=''></img>
                            </a>
                            <a href='https://x.com/xgrokerc20'>
                                <img data-aos='fade-left' src={icon_3} alt='logo' className=''></img>
                            </a>
                        </div>
                    </div>

                    {/* tokenomics */}

                    <img data-aos='fade-left' src={right} className='absolute right-0 -mt-52 lg:block hidden'></img>

                    <img data-aos='fade-right' src={left} className='absolute left-0 mt-36 lg:block hidden'></img>

                    {/* tokenomics */}

                    <div id='tokenomics'>

                        <h1 data-aos='fade-down' className='lg:text-5xl text-3xl text-center font-semibold'>
                            TOKENOMICS
                        </h1>

                        <div className='py-16 text-center'>

                            <div data-aos='fade-up' className='space-y-4 flex flex-col mx-auto'>
                                <p className='lg:text-2xl text-xl font-semibold'>
                                    Name: XGROK
                                </p>
                                <p className='lg:text-2xl text-xl font-semibold'>
                                    Ticker: $XGROK
                                </p>
                                <p className='lg:text-2xl text-xl font-semibold'>
                                    TAX: 0/0
                                </p>
                                <p className='lg:text-2xl text-xl font-semibold'>
                                    Max wallet: 2% at launch
                                </p>
                                <p className='lg:text-2xl text-xl font-semibold'>
                                    Supply: 10,000,000,000 XGROK
                                </p>

                                {/* <div className='mt-6'>
                                    <p className='lg:text-2xl text-xl font-semibold'>
                                        Contract Address:
                                    </p>
                                    <p className='lg:text-2xl text-sm font-semibold'>
                                        0xa49b6971e44ab9e9cee82d351f79f3090566d555
                                    </p>
                                </div> */}
                            </div>

                        </div>

                    </div>

                    {/* community */}

                    <div id='community'>

                        <h1 className='lg:text-5xl text-3xl text-center font-semibold'>
                            Community
                        </h1>

                        <div className='lg:flex items-center justify-center gap-8 py-16'>

                            <a href='https://t.me/xgrokerc20'>
                                <div data-aos='slide-right' className='bg-black-50 p-6 flex items-center gap-x-8 rounded-3xl lg:mb-0 mb-6 skew-shake-y'>
                                    <img src={telegram} className=''></img>
                                    <p className='lg:text-4xl text-2xl'>
                                        Join 𝕏-Grok<br></br>
                                        on Telegram
                                    </p>
                                </div>
                            </a>

                            <a href='https://x.com/xgrokerc20'>
                                <div data-aos='slide-left' className='bg-black-50 p-6 flex items-center gap-x-8 rounded-3xl skew-shake-y'>
                                    <img src={x} className='w-20'></img>
                                    <p className='lg:text-4xl text-2xl'>
                                        Follow 𝕏-Grok<br></br> on X
                                    </p>
                                </div>
                            </a>

                        </div>

                    </div>

                    {/* roadmap */}

                    <img src={bottom} className='absolute bottom-0 flex mx-auto right-0 left-0'></img>

                    <div
                        id="roadmap"
                        className="lg:py-12 py-5 lg:px-8 px-5 container mx-auto"
                    >
                        <h3 className="md:text-6xl text-2xl font-exo font-semibold text-center">
                            Roadmap
                        </h3>

                        <div className="lg:mt-12 mt-6 rounded-2xl bg-blue-200 lg:p-12 p-6">

                            <div className="h-1 w-full bg-black-50">

                            </div>

                            <div className='h-1 w-full line -translate-y-1'>

                            </div>

                            <div className="lg:flex items-baseline justify-center gap-32 lg:px-20 lg:space-y-0 space-y-6 -mt-2.5">

                                <div className="flex flex-col items-center">
                                    <div
                                        data-aos="zoom-in"
                                        className="absolute -mt-3.5 lg:block hidden"
                                    >
                                        <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle opacity="0.2" cx="19.0471" cy="19.4725" r="18.5609" fill="#48d6d2" />
                                            <circle cx="19.0471" cy="19.4726" r="11.1365" fill="#48d6d2" />
                                        </svg>

                                    </div>

                                    <div className="border-l border-white-50 lg:mt-5 mt-0 mb-3 h-14"></div>

                                    <div
                                        data-aos="flip-right"
                                        className="border border-white-50 rounded-2xl text-white-100 p-5 lg:w-80 hover:bg-purple-50"
                                    >
                                        <h5 className="text-2xl py-4 font-semibold">Phase 1</h5>

                                        <ul className="space-y-4 list-disc p-4">
                                            <li>Marketing by Top-Tier Influencers</li>
                                            <li>Partnership with Major Protocols on BASE</li>
                                            <li>CoinMarketCap Listing</li>
                                            <li>CoinGecko Listing</li>
                                            <li>500+ Holders</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="flex flex-col items-center">
                                    <div
                                        data-aos="zoom-in"
                                        className="absolute -mt-3.5 lg:block hidden"
                                    >
                                        <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle opacity="0.2" cx="19.0471" cy="19.4725" r="18.5609" fill="#48d6d2" />
                                            <circle cx="19.0471" cy="19.4726" r="11.1365" fill="#48d6d2" />
                                        </svg>
                                    </div>

                                    <div className="border-l border-white-50 lg:mt-5 mt-0 mb-3 h-14"></div>

                                    <div
                                        data-aos="flip-left"
                                        className="border border-white-50 rounded-2xl text-white-100 p-5 lg:w-80 hover:bg-purple-50"
                                    >
                                        <h5 className="text-2xl py-4 font-semibold">Phase 2</h5>

                                        <ul className="space-y-4 list-disc p-4">
                                            <li>5000+ Holders</li>
                                            <li>Multi-Chain Expansion</li>
                                            <li>5000+ Followers on X</li>
                                            <li>5000+ Members on Telegram</li>
                                        </ul>
                                    </div>
                                </div>

                                {/* <div className="flex flex-col items-center">
                                    <div
                                        data-aos="zoom-in"
                                        className="absolute -mt-3.5 lg:block hidden"
                                    >
                                        <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle opacity="0.2" cx="19.0471" cy="19.4725" r="18.5609" fill="#48d6d2" />
                                            <circle cx="19.0471" cy="19.4726" r="11.1365" fill="#48d6d2" />
                                        </svg>
                                    </div>

                                    <div className="border-l border-white-50 lg:mt-5 mt-0 mb-3 h-14"></div>

                                    <div
                                        data-aos="flip-right"
                                        className="border border-white-50 rounded-2xl text-white-100 p-5 lg:w-80 hover:bg-purple-50"
                                    >
                                        <p className='text-white-50'>November 2023-February 2024</p>

                                        <h5 className="text-2xl py-4 font-semibold">Phase 3</h5>

                                        <ul className="space-y-4 list-disc p-4">
                                            <li>XDoge Bird Swap</li>
                                            <li>CEX Listings</li>
                                            <li>XDoge Bird Academy</li>
                                            <li>25000+ Holders</li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className='text-center z-20 relative pt-12 pb-6 space-y-4'>
                        <p>Email: <a href='mailto:xgrokerc20@gmail.com' className='hover:underline'>xgrokerc20@gmail.com</a></p>
                        <p className=''>© Copyright, {new Date().getFullYear()} 𝕏-Grok. All rights reserved</p>
                    </div>

                </div>
            </div>

        </div >
    )
}
