import React, { useState } from 'react'
import logo_1 from '../assets/logo.png';



export default function Menu() {

  const [show, setShow] = useState(false);


  return (

    <header>

      <div className="lg:flex hidden items-center justify-between font-exo h-28 pt-4 ">
        <a href="/" className="flex items-center gap-x-5">
          <img src={logo_1} alt="Logo" className='w-20 rounded-full shadow-lg border-2' />
          <p className='text-2xl font-semibold'>𝕏-Grok</p>
        </a>

        <nav>
          <ul className="text-md flex justify-center gap-12 items-center text-white-100">
            <li><a href="/" className="">Home</a></li>
            <li><a href="#about" className="">About us</a></li>
            <li><a href="#tokenomics" className="">Tokenomics</a></li>
            <li><a href="#community" className="">Community</a></li>
            <li><a href="#roadmap" className="">Roadmap</a></li>
          </ul>
        </nav>
      </div>

      <div className="lg:hidden flex items-center justify-between pt-5 font-exo">
        <a href="/" className="flex items-center gap-x-4">
          <img src={logo_1} alt="Logo" className='w-20 rounded-full shadow-lg border-2' />
          <p className='text-xl font-semibold'>𝕏-Grok</p>
        </a>

        <button onClick={() => setShow(!show)} className="nav cursor-pointer">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-white-100">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
          </svg>

        </button>
      </div>

      {
        show ? <div className="fixed top-0 bottom-0 lg:left-0 p-2 w-3/4 overflow-y-auto font-exo font-semibold bg-black-50 z-30 left-0">

          <div className="mt-3 px-3 mb-12">
            <a href="/" className="flex items-center gap-x-5">
              <img src={logo_1} alt="Logo" className='w-20 rounded-full shadow-lg border-2' />
              <p className='text-xl font-semibold'>𝕏-Grok</p>
            </a>
          </div>
          <a href="/" className="">
            <button onClick={() => setShow(!show)} className="w-full py-2.5 mt-5 text-white-100 text-lg ml-4 font-semibold text-left">
              Home
            </button>
          </a>
          <a href="#about" className="">
            <button onClick={() => setShow(!show)} className="w-full py-2.5 mt-3 text-white-100 text-lg ml-4 font-semibold text-left">
              About us
            </button>
          </a>
          <a href="#tokenomics" className="">
            <button onClick={() => setShow(!show)} className="w-full py-2.5 mt-3 text-white-100 text-lg ml-4 font-semibold text-left">
              Tokenomics
            </button>
          </a>
          <a href="#community" className="">
            <button onClick={() => setShow(!show)} className="w-full py-2.5 mt-3 text-white-100 text-lg ml-4 font-semibold text-left">
              Community
            </button>
          </a>
          <a href="#roadmap" className="">
            <button onClick={() => setShow(!show)} className="w-full py-2.5 mt-3 text-white-100 text-lg ml-4 font-semibold text-left">
              Roadmap
            </button>
          </a>
        </div> : null
      }


    </header >
  )
}
